<template>
  <basic-container
    isCard
    :body-style="{ padding: '0', height: '100%' }"
    class="h-100p"
  >
    <el-container class="h-100p">
      <el-header height="50px" class="lh-50 bor-b">
        <basic-page-header
          @back="back"
          class="lh-50"
          :title="title"
          @click="back"
          icon="el-icon-download"
        ></basic-page-header>
      </el-header>

      <el-container style="height: calc(100% - 50px)">
        <el-main class="ov-a bor-r">
          <div class="pad-b-10 mar-t-10">
            <el-form
              class="pad-10"
              :model="ruleForm"
              ref="ruleForm"
              label-width="90px"
              size="small"
              labelPosition="right"
              :rules="ruleModel"
              :disabled="type == 'view'"
            >
              <el-row v-if="type == ''">
                <el-col :span="8">
                  <el-form-item label="选择素材" prop="name">
                    <el-select
                      v-model="materialId"
                      filterable
                      clearable
                      placeholder="请选择"
                      @change="changeMaterial"
                    >
                      <el-option
                        v-for="item in materials"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="节目名称" prop="name">
                    <el-input
                      placeholder="请输入节目名称"
                      v-model="ruleForm.name"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="节目别名" prop="alias">
                    <el-input
                      placeholder="请输入节目别名"
                      v-model="ruleForm.alias"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="信息类别" prop="category_name">
                    <!-- <el-cascader
                      v-if="type != 'view'"
                      v-model="categoryId"
                      ref="cascader"
                      :props="props"
                      filterable
                      @change="changeCom"
                    ></el-cascader>
                    <span v-if="type == 'view'">{{
                      ruleForm.category_name
                    }}</span> -->

                    <span v-if="type == 'view'">{{
                      ruleForm.category_name
                    }}</span>
                    <el-cascader
                      v-if="type != 'view'"
                      v-model="ruleForm.category_id"
                      @change="changeCom"
                      ref="cascader"
                      :props="{
                        value: 'id',
                        label: 'name',
                      }"
                      :options="equiList"
                      filterable
                      :show-all-levels="true"
                    ></el-cascader>

                    <!-- <el-cascader
                      v-model="ruleForm.category_id"
                      @change="changeCom"
                      :options="equiList"
                      filterable
                      :show-all-levels="true"
                    ></el-cascader> -->
                    <!-- 
                    <el-select
                      v-model="ruleForm.category_id"
                      filterable
                      @change="changeCom"
                      placeholder="请选择信息类别"
                    >
                      <el-option
                        v-for="(item, i) in equiList"
                        :key="i"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select> -->
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item label="排序" prop="sort">
                    <el-input
                      placeholder="请输入节目别名"
                      v-model="ruleForm.sort"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="8">
                  <el-form-item label="节目类型" prop="program_type">
                    <el-select
                      v-model="ruleForm.program_type"
                      filterable
                      placeholder="请选择节目类型"
                    >
                      <el-option
                        v-for="(item, index) in filesList"
                        :key="index"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="16">
                  <el-form-item label="备注" prop="tag">
                    <el-input
                      type="textarea"
                      :rows="5"
                      placeholder="请输入事由"
                      v-model="ruleForm.tag"
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <div class="pad-20 pad-t-5">
              <el-divider content-position="left">上传节目 </el-divider>
              <div class="pad-10" v-if="imgform.imgUrl.length == 0">
                暂无文件
              </div>
              <avue-form :option="option" v-model="imgform"></avue-form>
              <div class="btnClass mar-l-40p">
                <el-button
                  size="small"
                  v-if="type == 'view'"
                  class="mar-t-10 mar-r-5"
                  type="primary"
                  @click="type = 'update'"
                  >编辑</el-button
                >
                <el-button
                  v-if="type != 'view'"
                  size="small"
                  class="mar-t-10 mar-r-5"
                  type="primary"
                  @click="save"
                  >保存</el-button
                >
                <el-button
                  size="small"
                  class="mar-t-10 mar-r-10"
                  type=""
                  @click="back"
                  >取消</el-button
                >
              </div>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </basic-container>
</template>

<script>
import standardService from "@/api/metadata/standard";
import {
  getMetaCommonInfo,
  getPicListByIds,
  updateMetaCommon,
  saveMetaCommon,
  getMetaCommonList,
  getMetaCommonTree,
  getMetaCommonListTree,
} from "@/api/busiMode/metaCommon";
import { mapGetters, mapState } from "vuex";
import { dateFormat } from "@/util/date";
import * as dictbizService from "@/api/system/dictbiz";
import material from "./material";

export default {
  components: { material },
  data() {
    return {
      ruleModel: {},
      title: "",
      type: "",
      cardTypeList: [],
      dialogVisible: false,
      filesList: [
        {
          value: "picture",
          label: "图片",
          title: "请上传BMP、JPG、PNG、GIF、JPEG的文件",
        },
        {
          value: "flash",
          label: "Flash",
        },
        {
          value: "audio",
          label: "音频",
        },
        {
          value: "video",
          label: "视频",
        },
        {
          value: "document",
          label: "文本",
        },
        {
          value: "pdf",
          label: "PDF",
        },
        {
          value: "web",
          label: "网页",
        },
        {
          value: "doc-Word",
          label: "文档",
        },
        {
          value: "ppt",
          label: "PPT",
        },
        {
          value: "web",
          label: "网页",
          title: "请上传HTML、HTM的文件",
        },
        {
          value: "realStream",
          label: "视频直播",
        },
        {
          value: "dataSource",
          label: "通用节目",
        },
        {
          value: "doc-Word",
          label: "文档",
        },
      ],
      materialId: "",
      materials: [],
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          const { level } = node;
          getMetaCommonList("wisdom_park.wp_publish_category", {
            current: 1,
            size: 500,
            wheres: [
              {
                field: "parent_id",
                value: node.value || 0,
              },
            ],
          }).then((res) => {
            // let data = res.data.data.records;
            let objs = [];
            res.data.data.records.forEach((e) => {
              objs.push({
                value: e.id,
                label: e.name,
              });
            });
            resolve(objs);
          });
        },
      },
      option: {
        labelWidth: 0,
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "",
            prop: "imgUrl",
            type: "upload",
            listType: "picture-card",
            loadText: "附件上传中，请稍等",
            span: 24,

            propsHttp: {
              res: "data",
              url: "link",
              name: "attachId",
            },
            tip: "单个节目的大小不能超过4GB,且节目名称不能重复",
            action: "/api/jida-resource/oss/endpoint/put-file-attach",
          },
        ],
      },
      imgform: {
        imgUrl: [],
      },
      ruleForm: {},
      categoryId: [],
      params: {
        zoom: 4,
        zoomEnable: false,
        dragEnable: true,
      },
      ruleModel: {},
      equiList: [],
      dialogVisible: false,
      model: "wisdom_park.wp_publish_program",
      categoryModel: "wisdom_park.wp_publish_category",
      materialModel: "wisdom_park.wp_publish_material",
      selectionList: [],
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  watch: {
    type: {
      immediate: true,
      handler(val) {
        if (val == "view") {
          this.option.disabled = true;
          this.option = JSON.parse(JSON.stringify(this.option));
        } else {
          this.option.disabled = false;
          this.option = JSON.parse(JSON.stringify(this.option));
        }
      },
    },
  },
  mounted() {
    this.queryByModelCode();
    this.getMetaCommonListBymaterialModel();
    this.getTree();
    if (this.$router.currentRoute.query.equipment) {
      this.ruleForm.id = this.$router.currentRoute.query.equipment;
      this.getMetaCommonInfo();
      this.type = this.$router.currentRoute.query.type;
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  props: {},
  methods: {
    selection(val) {
      this.selectionList = val;
    },
    // importantMater() {
    //   this.dialogVisible = true;
    //   this.getMetaCommonListBymaterialModel();
    // },
    getMetaCommonListBymaterialModel() {
      let body = {
        current: 1,
        size: 500,
      };
      getMetaCommonList(this.materialModel, body).then((res) => {
        if (res.data.code == 200) {
          const data = res.data.data;
          this.materials = data.records;
        }
      });
    },
    changeMaterial(val) {
      console.log(val);
      let obj = {};
      this.materials.forEach((e) => {
        if (e.id == val) {
          obj = e;
          return;
        }
      });
      delete obj.id;
      obj.program_type = obj.material_type;
      delete obj.material_type;
      this.ruleForm = obj;
      if (this.ruleForm.file_url) {
        this.getpicList(this.ruleForm.file_url);
      }
    },

    getTree() {
      let obj = {
        labelField: "name", //标签显示字段
        valueField: "id", //取值的字段 key
        isLazy: false,
        releaseField: "parent_id", //关联字段
      };
      getMetaCommonListTree(this.categoryModel, obj).then((res) => {
        if (res.data.code == 200) {
          this.equiList = res.data.data;
        }
      });
    },
    changeCom(val) {
      this.ruleForm.category_id = val[1];
      let node = this.$refs.cascader.getCheckedNodes();
      if (node) {
        this.ruleForm.category_name = node[0].label;
      }
    },
    getMetaCommonInfo() {
      let obj = {
        pk: "id",
        id: this.ruleForm.id,
      };
      getMetaCommonInfo(this.model, obj).then((res) => {
        if (res.data.code == 200) {
          this.ruleForm = res.data.data;
          if (this.ruleForm.file_url) {
            this.getpicList(this.ruleForm.file_url);
          }
          if (this.ruleForm.category_id && this.type == "update") {
            this.getMetaCommonInfoByCategoryId();
          }
        }
      });
    },
    getMetaCommonInfoByCategoryId() {
      let obj = {
        pk: "id",
        id: this.ruleForm.category_id,
      };
      getMetaCommonInfo(this.categoryModel, obj).then((res) => {
        if (res.data.code == 200) {
          this.categoryId = [res.data.data.parent_id + "", res.data.data.id];
          console.log(this.categoryId);
          //this.categoryId = ["企业明白纸", "123123"];
        }
      });
    },
    getpicList(ids) {
      getPicListByIds(ids).then((res) => {
        if (res.data.code == 200) {
          let arr = [];
          res.data.data.forEach((pic) => {
            arr.push({
              label: pic.id,
              value: pic.link,
            });
          });
          this.imgform.imgUrl = arr;
        }
      });
    },
    queryByModelCode() {
      standardService.getModelRule(this.model).then((res) => {
        this.ruleModel = res;
      });
    },
    submit() {
      return new Promise((resolve) => {
        this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            resolve({
              valid: true,
              form: this.ruleForm,
            });
          } else {
            let fields = this.$refs["ruleForm"].fields,
              firstError = {
                field: "",
                message: "",
                name: "",
              };
            for (let i = 0; i < fields.length; i++) {
              if (fields[i].validateState === "error") {
                firstError.message = fields[i].validateMessage;
                firstError.field = fields[i].prop;
                firstError.name = fields[i].label;
                break;
              }
            }
            resolve({
              valid: false,
              form: this.ruleForm,
              error: firstError,
            });
          }
        });
      });
    },
    back() {
      this.$router.push({
        path: "/information/program",
      });
    },
    save() {
      Promise.all([this.submit()]).then((forms) => {
        //照片
        if (this.imgform) {
          let imgList = [];
          this.imgform.imgUrl.forEach((e) => {
            if (e.label) {
              imgList.push(e.label);
            }
          });
          this.ruleForm.file_url = imgList.toString();
        }

        if (this.ruleForm.id) {
          let o = {
            update: this.ruleForm,
            primaryKeyValue: this.ruleForm["id"],
            primaryKey: "id",
          };
          updateMetaCommon(this.model, o).then((res) => {
            if (res.data.code == 200) {
              this.back();
            }
          });
        } else {
          saveMetaCommon(this.model, {
            values: this.ruleForm,
          }).then((res) => {
            if (res.data.code == 200) {
              this.back();
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.drbtn {
  position: absolute;
  right: 20px;
  top: 10px;
}
/deep/ .el-divider--horizontal {
  margin: 20px 0 !important;
}

/deep/ .el-divider__text.is-left {
  left: 0px;
}
/deep/ .el-divider__text {
  padding: 0px 10px 0px 0px;
}
/deep/ .el-upload--picture-card {
  width: 68px;
  height: 68px;
  line-height: 72px;
  vertical-align: top;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 68px;
  height: 68px;
}
.btnClass {
  display: flow-root;
  height: 50px;
  line-height: 50px;
}
/deep/ .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
/deep/ .el-select {
  width: 100%;
}
/deep/ .el-cascader {
  width: 100%;
}
</style>
